
import { computed, defineComponent, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useHead } from "@vueuse/head";
import { useStore } from "vuex";
import "@fullcalendar/core";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import locale from "@fullcalendar/core/locales/pt-br";

import CModal from "@/components/Modal.vue";
import PFullCalendar from "primevue/fullcalendar";
import PProgressBar from "primevue/progressbar";

import { compareDates, formatDateEn, formatHours } from "@/libs/utils";

const VAgendaAttendance = defineComponent({
  name: "VAgendaAttendance",
  components: { CModal, PFullCalendar, PProgressBar },
  setup() {
    useHead({ title: "Atendimentos na agenda | obmed" });

    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const _id = Number(route.params._id);
    const loading = reactive({ attendance: false });

    const attendanceDays = computed(() => store.state.agenda.attendanceDays);
    const events = computed(() =>
      attendanceDays.value.map((attendanceDay) => ({
        id: attendanceDay.id,
        title: `Das ${formatHours(attendanceDay.hr_inicio_atendimento)} às ${formatHours(
          attendanceDay.hr_fim_atendimento
        )}`,
        displayEventTime: false,
        eventTime: "false",
        startRecur: attendanceDay.dt_vigencia_inicial,
        endRecur: attendanceDay.dt_vigencia_final,
        startTime: attendanceDay.hr_inicio_atendimento,
        endTime: attendanceDay.hr_fim_atendimento,
        daysOfWeek: [
          ["DOMINGO", "SEGUNDA", "TERCA", "QUARTA", "QUINTA", "SEXTA", "SABADO"].findIndex(
            (item) => item === attendanceDay.nm_dia_semana
          ),
        ],
      }))
    );

    function handleDateClick(event: any) {
      if (compareDates(event.dateStr))
        router.push({
          name: "agenda-attendance-add",
          params: { _id },
          query: { date: event.dateStr },
        });
      else
        store.commit("addToast", {
          summary: "Escolha uma data posterior a atual",
          severity: "warn",
        });
    }

    function handleEventClick({ event }: any) {
      router.push({
        name: "agenda-attendance-edit",
        params: { _id },
        query: { id: event.id },
      });
    }

    const options = ref({
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      initialDate: formatDateEn(new Date().toDateString()),
      headerToolbar: {
        left: "prev,next",
        center: "title",
        right: "dayGridMonth,timeGridWeek,timeGridDay",
      },
      editable: false,
      locale,
      eventClick: handleEventClick,
      dateClick: handleDateClick,
    });

    async function getAgendaAttendanceDays() {
      loading.attendance = true;
      await store.dispatch("getAgendaAttendanceDays", { _id });
      loading.attendance = false;
    }

    function handleClose() {
      router.back();
    }

    getAgendaAttendanceDays();

    return { attendanceDays, loading, events, options, handleClose };
  },
});

export default VAgendaAttendance;
